import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/solutionsearch",
    name: "SolutionSearch",
    meta: { title: "解决方案搜索-苏创信息" },
    component: () => import("../views/solution/SolutionSearch.vue"),
  },
  {
    path: "/home2",
    name: "home",
    meta: { title: "广西苏创信息科技有限公司官网" },
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/news",
        name: "News",
        meta: { title: "新闻中心-苏创信息" },
        component: () => import("../views/news/News.vue"),
      },
      {
        path: "/newsdetails",
        name: "NewsDetails",
        meta: { title: "新闻详情-苏创信息" },
        component: () => import("../views/news/NewsDetails.vue"),
      },
      {
        path: "/about",
        name: "About",
        meta: { title: "关于我们-苏创信息" },
        component: () => import("../views/about/About.vue"),
      },
      {
        path: "/contact",
        name: "Contact",
        meta: { title: "联系我们-苏创信息" },
        component: () => import("../views/about/Contact.vue"),
      },
      {
        path: "/solution",
        name: "Solution",
        meta: { title: "解决方案中心-苏创信息" },
        component: () => import("../views/solution/Solution.vue"),
      },
      {
        path: "/solutiondetails",
        name: "SolutionDetails",
        meta: { title: "解决方案详情-苏创信息" },
        component: () => import("../views/solution/SolutionDetails.vue"),
      },
      {
        path: "/job",
        name: "job",
        meta: { title: "招纳贤士-苏创信息" },
        component: () => import("../views/job/Job.vue"),
      },
      {
        path: "/home",
        name: "Home",
        meta: { title: "广西苏创信息科技有限公司官网" },
        component: () => import("../views/home/Home.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
