<template>
  <div id="app">
    <div>
      <router-view v-wechat-title="$route.meta.title"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
body {
  font-size: 14px !important;
  background-color: #f6f4f7 !important;
}
a {
  text-decoration: none;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
li {
  list-style-type: none;
}
</style>
