import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    basicinfo: null, //基本信息
  },
  mutations: {
    changeBasicinfo(state, value) {
      state.basicinfo = value;
    },
  },
  actions: {
    setBasicinfo(context, value) {
      context.commit("changeBasicinfo", value);
    },
  },
  modules: {},
});
