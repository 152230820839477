import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import "swiper/swiper-bundle.css";
import VueAwesomeAwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeAwiper);

import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);

//import ElementUI from "element-ui";
//import "element-ui/lib/theme-chalk/index.css";
//Vue.use(ElementUI);

//百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, { ak: "XXXXXXXXXXXXXXXXXXXX" });

//axios
import Axios from "axios";
Axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//本地
// Axios.defaults.baseURL = "http://localhost/dev-api";
// Vue.prototype.$axios = Axios;
// Vue.prototype.StaticResDomain = "http://localhost/dev-api";
// Vue.prototype.EditorStaticResDomain = "http://localhost";
// Vue.prototype.SearchResUrl = "http://localhost:9091/scWeb/#/";
//服务器
const protocolHost = document.location.protocol + "//work.gxscinfo.com";    //获取网络协议
const port = "8088"
Axios.defaults.baseURL = `${protocolHost}:${port}/prod-api`;
Vue.prototype.$axios = Axios;
Vue.prototype.StaticResDomain = `${protocolHost}:${port}/prod-api`;
Vue.prototype.EditorStaticResDomain = '';
Vue.prototype.SearchResUrl = `${protocolHost}/#/`;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
